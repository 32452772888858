import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TransferDetails from './TransferDetails'; // The details component for transfers
import './ManagementList.css'; // Use the combined CSS file
import config from '../config/config.json';

const edc_management_url = config.edc_management_url;
const apiKey = config.apiKey;

const authHeaders = {
  headers: {
    'X-Api-Key': `${apiKey}`,
    'Content-Type': 'application/json'
  },
};

const TransferList = () => {
  const [transfers, setTransfers] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]); // Change to store unique transfer types
  const [selectedTransfer, setSelectedTransfer] = useState(null);
  const [error, setError] = useState(null);
  const [filterState, setFilterState] = useState(''); // State filter
  const [filterType, setFilterType] = useState(''); // Type filter
  const [filterCreatedAt, setFilterCreatedAt] = useState(''); // Created date filter

  const jsonldBody = {
    "@context": {
      "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
    },
    "@type": "QuerySpec",
    "offset": 0,
    "limit": 200,
    "sortOrder": "DESC"
  };

  useEffect(() => {
    // Fetch all transfer processes from the backend
    axios.post(`${edc_management_url}/management/v3/transferprocesses/request`, jsonldBody, authHeaders)
      .then((response) => {
        setTransfers(response.data);

        // Extract unique type values
        const uniqueTransferTypes = [...new Set(response.data.map((transfer) => transfer.type))];
        setUniqueTypes(uniqueTransferTypes);
      })
      .catch((error) => setError('Error fetching transfer processes'));
  }, []);

  // Helper function to check if a created date matches the filter
  const matchesCreatedAtFilter = (transferStateTimestamp) => {
    if (!filterCreatedAt) return true;
    const filterDate = new Date(filterCreatedAt).setHours(0, 0, 0, 0); // Filter date set to start of day
    const createdDate = new Date(transferStateTimestamp).setHours(0, 0, 0, 0);
    return createdDate === filterDate;
  };

  // Filter transfers based on the selected filters
  const filteredTransfers = transfers.filter((transfer) => {
    const matchesState = filterState ? transfer.state === filterState : true;
    const matchesType = filterType ? transfer.type === filterType : true;
    const matchesCreatedAt = filterCreatedAt ? matchesCreatedAtFilter(transfer.stateTimestamp) : true;

    return (
      matchesState && matchesType && matchesCreatedAt &&
      ['COMPLETED', 'TERMINATED', 'SUSPENDED', 'REQUESTED','DEPROVISIONED'].includes(transfer.state) // Ensure only specified statuses are included
    );
  });

  return (
    <div className="management-container">
      <div className="list-container">

        
        <h2>Transfer Processes List</h2>

        <div className="filter-container">
          <div>
            <label htmlFor="stateFilter">State:</label>
            <select
              id="stateFilter"
              className="styled-select"
              value={filterState}
              onChange={(e) => setFilterState(e.target.value)}
            >
              <option value="">All</option>
              <option value="COMPLETED">Completed</option>
              <option value="TERMINATED">Terminated</option>
              <option value="SUSPENDED">Suspended</option>
              <option value="REQUESTED">Requested</option>
              <option value="DEPROVISIONED">Deprovisioned</option>
            </select>&nbsp;&nbsp;&nbsp;&nbsp;
            <label htmlFor="createdAtFilter">Created on Date:</label>
            <input
              id="createdAtFilter"
              className="styled-input"
              type="date"
              value={filterCreatedAt}
              onChange={(e) => setFilterCreatedAt(e.target.value)}
            />
          </div>
        </div>
        <div className='filter-container'>
          <label htmlFor="typeFilter">Type:</label>
          <select
            id="typeFilter"
            className="styled-select"
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <option value="">All</option>
            {uniqueTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        {error ? (
          <p>{error}</p>
        ) : (
          <div className="block-container">
            {filteredTransfers.map((transfer) => (
              <div
                key={transfer["@id"]}
                className="block-item"
                onClick={() => setSelectedTransfer(transfer)}
              >
                <p><strong>ID:</strong> {transfer["@id"]}</p>
                <p><strong>State:</strong> {transfer.state || "Unknown"}</p>
                <p><strong>Type:</strong> {transfer.type}</p>
                <p><strong>State Timestamp:</strong> {new Date(transfer.stateTimestamp).toLocaleDateString()}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Transfer Details Panel */}
      <div className="details-panel">
        {selectedTransfer ? (
          <TransferDetails
            transfer={selectedTransfer}
            onClose={() => setSelectedTransfer(null)}
          />
        ) : (
          <p>Select a transfer to view details</p>
        )}
      </div>
    </div>
  );
};

export default TransferList;
