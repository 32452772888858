import React, { useState } from 'react';
import axios from 'axios';
import config from '../config/config.json';
import CreateDescriptorForm from './CreateDescriptorForm'; // Import the form for updating the descriptor
import Modal from './Modal'; // Import the modal component

const dtr_url = config.dtr_url; // Get the DTR URL from the config
const bpn = config.bpn; // Get the BPN from the config
const authHeaders = {
  headers: {
    'Edc-Bpn': `${bpn}`, // Use the BPN in the header
    'Content-Type': 'application/json',
  },
};

const DtrDetails = ({ descriptor, onDelete, onUpdate }) => {
  const [error, setError] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false); // State to show/hide update form

  // Function to delete the descriptor
  const deleteDescriptor = async () => {
    try {
      // Encode the descriptor ID as UTF-8, Base64, and URL-encoded
      const aasIdentifier = encodeURIComponent(btoa(unescape(encodeURIComponent(descriptor.id))));
      
      await axios.delete(`${dtr_url}/semantics/registry/api/v3/shell-descriptors/${aasIdentifier}`, authHeaders);
      alert('Digital Twin deleted successfully!');
      onDelete(); // Trigger the callback to reset the selected descriptor
    } catch (err) {
      console.error(err);
      setError('Failed to delete Digital Twin');
    }
  };

  // Function to toggle the update form
  const openUpdateForm = () => {
    setShowUpdateForm(true); // Show the update form in a modal
  };

  return (
    <div className="descriptor-details">
      <h2>Digital Twin Details</h2>
      <p><strong>ID:</strong> {descriptor.id}</p>
      <p><strong>ID Short:</strong> {descriptor.idShort || "No ID Short available"}</p>
      <p><strong>Description:</strong> {descriptor.description.join(', ') || "No description available"}</p>
      <p><strong>Specific Asset IDs:</strong> {JSON.stringify(descriptor.specificAssetIds)}</p>
      <p><strong>Submodel Descriptors:</strong> {JSON.stringify(descriptor.submodelDescriptors)}</p>
      
      <div className="button-container">
        <button onClick={deleteDescriptor}>Delete</button>
        <button onClick={openUpdateForm}>Update</button>
      </div>
      {error && <p>{error}</p>}

      <Modal isOpen={showUpdateForm} onClose={() => setShowUpdateForm(false)}>
        <CreateDescriptorForm 
          descriptor={descriptor} // Pass the selected descriptor to prepopulate the form
          onCreate={(updatedDescriptor) => {
            setShowUpdateForm(false); // Close the modal after successful update
            onUpdate(updatedDescriptor); // Handle the update callback
          }}
        />
      </Modal>
    </div>
  );
};

export default DtrDetails;
