import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AssetDetails from './AssetDetails';
import CreateAssetForm from './CreateAssetForm';
import Modal from './Modal';
import './ManagementList.css'; // Use the combined CSS file
import config from '../config/config.json';

const edc_management_url = config.edc_management_url;
const apiKey = config.apiKey;

const AssetsList = () => {
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state for Create Asset Form
  const [searchTerm, setSearchTerm] = useState(""); // State for search input

  const authHeaders = {
    headers: {
      'X-Api-Key': `${apiKey}`,
      'Content-Type': 'application/json'
    },
  };
  const jsonldBody = {
    "@context": {
      "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
    },
    "@type": "QuerySpec"
  };

  useEffect(() => {
    // Fetch assets from the backend
    axios.post(`${edc_management_url}/management/v3/assets/request`, jsonldBody, authHeaders)
      .then((response) => {
        setAssets(response.data);
      })
      .catch((error) => {
        console.error('Error details:', error);
        setError('Error fetching assets');
      });
  }, []);

  const addNewAsset = (newAsset) => {
    setAssets((prevAssets) => [...prevAssets, newAsset]);
    setIsModalOpen(false); // Close the modal after asset creation
  };

  // Filter assets based on search term (id, name, or description)
  const filteredAssets = assets.filter((asset) => {
    const id = asset["@id"]?.toLowerCase() || "";
    const name = asset.properties?.name?.toLowerCase() || "";
    const description = asset.properties?.description?.toLowerCase() || "";
    const term = searchTerm.toLowerCase();

    return id.includes(term) || name.includes(term) || description.includes(term);
  });

  return (
    <div className="management-container">
      <div className="list-container">
        <h2>Assets List</h2>

        {/* Search Input */}
        <div className="filter-container"><input
          type="text"
          placeholder="Search by ID, name, or description"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="styled-input"
        /></div>

        {error ? (
          <p>{error}</p>
        ) : (
          <div className="block-container">
            {filteredAssets.map((asset) => (
              <div
                key={asset["@id"]}
                className="block-item"
                onClick={() => setSelectedAsset(asset)}
              >
                <p><strong>ID:</strong> {asset["@id"]}</p>
                <p><strong>Name:</strong> {asset.properties?.name || "No name available"}</p>
                <p><strong>Description:</strong> {asset.properties?.description || "No description available"}</p>
              </div>
            ))}
          </div>
        )}

        <div className="button-container">
          <button onClick={() => setIsModalOpen(true)}>Create Asset</button>
        </div>
      </div>

      {/* Asset Details Panel */}
      <div className="details-panel">
        {selectedAsset ? (
          <AssetDetails
            asset={selectedAsset}
            onDelete={() => setSelectedAsset(null)}
            onUpdate={(updatedAsset) => setSelectedAsset(updatedAsset)}
          />
        ) : (
          <h3>Select an asset to view details</h3>
        )}
      </div>

      {/* Modal for creating a new asset */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <CreateAssetForm onCreate={addNewAsset} />
      </Modal>
    </div>
  );
};

export default AssetsList;
