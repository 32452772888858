import React, { createContext, useState } from 'react';

// Create the context
export const WebhookContext = createContext();

// WebhookContext provider component
export const WebhookProvider = ({ children }) => {
  const [webhookData, setWebhookData] = useState(null); // To store the latest webhook data
  const [newDataReceived, setNewDataReceived] = useState(false); // To track notification state

  return (
    <WebhookContext.Provider value={{ webhookData, setWebhookData, newDataReceived, setNewDataReceived }}>
      {children}
    </WebhookContext.Provider>
  );
};
