import React, { useContext } from 'react';
import { WebhookContext } from './WebhookContext';

const WebhookListener = () => {
  const { webhookData } = useContext(WebhookContext); // Access webhook data from context

  return (
    <div>
      <h1>Webhook Data:</h1>
      {webhookData ? (
        <pre>{JSON.stringify(webhookData, null, 2)}</pre> // Display the data if present
      ) : (
        <p>No data received yet</p>
      )}
    </div>
  );
};

export default WebhookListener;
