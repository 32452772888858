import React, { useState } from 'react';
import axios from 'axios';
import config from '../config/config.json';

const edc_management_url = config.edc_management_url;
const apiKey = config.apiKey;
const backendurl=config.backend_url.concat('/webhook');

const authHeaders = {
  headers: {
    'X-Api-Key': `${apiKey}`,
    'Content-Type': 'application/json'
  },
};

const NegotiationDetails = ({ negotiation, onClose }) => {
  const [agreement, setAgreement] = useState(null);
  const [state, setState] = useState(null);
  const [error, setError] = useState(null);

  const fetchAgreement = () => {
    axios.get(`${edc_management_url}/management/v3/contractnegotiations/${negotiation["@id"]}/agreement`, authHeaders)
      .then((response) => setAgreement(response.data))
      .catch(() => setError('Error fetching agreement'));
  };

  const fetchState = () => {
    axios.get(`${edc_management_url}/management/v3/contractnegotiations/${negotiation["@id"]}/state`, authHeaders)
      .then((response) => setState(response.data))
      .catch(() => setError('Error fetching state'));
  };

  const terminateNegotiation = () => {
    axios.post(`${edc_management_url}/management/v3/contractnegotiations/${negotiation["@id"]}/terminate`, {
      "@context": {
      "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
      },
      "@type": "https://w3id.org/edc/v0.0.1/ns/TerminateNegotiation",
      "reason": "Terminated by user request",
      "@id": `${negotiation["@id"]}`
          }, authHeaders)
      .then(() => {
        setError(null);
        alert('Negotiation terminated successfully');
        onClose();
      })
      .catch((err) => setError( err.response?.data?.[0]?.message ));
  };

  const initiateTransfer = () => {
    const transferRequestBody = {
      "@context": {
        "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
      },
      "@type": "https://w3id.org/edc/v0.0.1/ns/TransferRequest",
      "protocol": "dataspace-protocol-http",
      "counterPartyAddress": negotiation.counterPartyAddress,
      "counterPartyId": negotiation.counterPartyId,
      "contractId": negotiation.contractAgreementId,
      "transferType": "HttpData-PUSH",
      "dataDestination": {
        "type": "HttpData",
        "baseUrl": backendurl
      },
      "privateProperties": {
        "receiverHttpEndpoint": backendurl
      }
    };

    axios.post(`${edc_management_url}/management/v3/transferprocesses/`, transferRequestBody, authHeaders)
      .then(() => {
        setError(null);
        alert('Transfer initiated successfully');
      })
      .catch(() => setError('Error initiating transfer'));
  };

  return (
    <div className="negotiation-details">
      <h3>Negotiation Details</h3>
      <p><strong>ID:</strong> {negotiation["@id"]}</p>
      <p><strong>Counterparty:</strong> {negotiation.counterPartyId || "N/A"}</p>
      <p><strong>Contract Offer Id:</strong> {negotiation.contractAgreementId || "N/A"}</p>

      {/* Display fetched agreement if available */}
      {agreement && (
        <div>
          <h4>Agreement</h4>
          <p>{JSON.stringify(agreement)}</p>
        </div>
      )}

      {/* Display fetched state if available */}
      {state && (
        <div>
          <h4>State</h4>
          <p>{JSON.stringify(state)}</p>
        </div>
      )}

      {/* Error handling */}
      {error && <p className="error">{error}</p>}

      {/* Buttons for agreement, state, termination, and transfer initiation */}
      <div className="button-container">
        <button onClick={fetchState}>Check State</button>
        <button onClick={terminateNegotiation}>Terminate</button>
        <button onClick={initiateTransfer}>Initiate Transfer</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default NegotiationDetails;
