import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DtrDetails from './DtrDetails';
import Modal from './Modal';
import './ManagementList.css'; // Use the combined CSS file
import config from '../config/config.json';

const dtr_url = config.dtr_url; // Assuming dtr_url is defined in the config file
const bpn = config.bpn; // Retrieve bpn from config

const DtrList = () => {
  const [shellDescriptors, setShellDescriptors] = useState([]); // Initialize as an array
  const [selectedDescriptor, setSelectedDescriptor] = useState(null);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state for any form you might have
  
  // Update headers to use Edc-Bpn instead of X-Api-Key
  const authHeaders = {
    headers: {
      'Edc-Bpn': `${bpn}`, // Use the bpn in the header
      'Content-Type': 'application/json',
    },
  };

  useEffect(() => {
    // Fetch shell descriptors from the backend
    axios.get(`${dtr_url}/semantics/registry/api/v3/shell-descriptors`, authHeaders)
      .then((response) => {
        // Set state to the result array from the response
        setShellDescriptors(response.data.result); // Access the `result` property directly
      })
      .catch((error) => {
        console.error('Error details:', error);
        setError('Error fetching shell descriptors');
      });
  }, []);

  const addNewDescriptor = (newDescriptor) => {
    setShellDescriptors((prevDescriptors) => [...prevDescriptors, newDescriptor]);
    setIsModalOpen(false); // Close the modal after descriptor creation
  };

  return (
    <div className="management-container">
      <div className="list-container">
        <h2>Digital Twin List</h2>
        {error ? (
          <p>{error}</p>
        ) : (
          <div className="block-container">
            {shellDescriptors.map((descriptor) => (
              <div
                key={descriptor.id} // Use the unique `id` for the key
                className="block-item"
                onClick={() => setSelectedDescriptor(descriptor)}
              >
                <p><strong>ID:</strong> {descriptor.id}</p>
                <p><strong>ID Short:</strong> {descriptor.idShort || "No ID Short available"}</p>
                <p><strong>Description:</strong> {descriptor.description.join(', ') || "No description available"}</p>
              </div>
            ))}
          </div>
        )}
        <div className="button-container">
          <button onClick={() => setIsModalOpen(true)}>Create Shell Descriptor</button>
        </div>
      </div>

      {/* Descriptor Details Panel */}
      <div className="details-panel">
        {selectedDescriptor ? (
          <DtrDetails
            descriptor={selectedDescriptor}
            onDelete={() => setSelectedDescriptor(null)}
            onUpdate={(updatedDescriptor) => setSelectedDescriptor(updatedDescriptor)}
          />
        ) : (
          <h3>Select a descriptor to view details</h3>
        )}
      </div>

      {/* Modal for creating a new shell descriptor */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {/* Replace with your form component for creating new descriptors */}
      </Modal>
    </div>
  );
};

export default DtrList;
