import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Modal.css'; // Use the updated modal styles
import config from '../config/config.json';

const edc_management_url = config.edc_management_url;
const apiKey=config.apiKey;
const authHeaders = {
  headers: {
    'X-Api-Key': `${apiKey}`,
    'Content-Type': 'application/json'
  },
};
const jsonldBody ={
  "@context": {
    "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
  },
  "@type": "QuerySpec"
};

const CreateContractDefinitionForm = ({ onCreate, onClose }) => {
  const [definitionId, setDefinitionId] = useState('');
  const [accessPolicyId, setAccessPolicyId] = useState('');
  const [contractPolicyId, setContractPolicyId] = useState('');
  const [selectedAsset, setSelectedAsset] = useState(''); // Single asset selection
  const [policies, setPolicies] = useState([]); // For policy dropdown
  const [assets, setAssets] = useState([]); // For assets dropdown
  const [error, setError] = useState(null);
    
  // Fetch available policies and assets when the component loads
  useEffect(() => {
    // Fetch policy definitions
    axios.post(`${edc_management_url}/management/v3/policydefinitions/request`,jsonldBody, authHeaders)
      .then((response) => {
        setPolicies(response.data);
      })
      .catch((err) => setError('Error fetching policies'));

    // Fetch assets
    axios.post(`${edc_management_url}/management/v3/assets/request`,jsonldBody, authHeaders)
      .then((response) => {
        setAssets(response.data);
      })
      .catch((err) => setError('Error fetching assets'));
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Structure for contract definition
    const newContractDefinition = {
      "@context": {
        "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
      },
      "@id": definitionId,
      "accessPolicyId": accessPolicyId,
      "contractPolicyId": contractPolicyId,
      "assetsSelector": {
        "@type": "CriterionDto",
        "operandLeft": "https://w3id.org/edc/v0.0.1/ns/id",
        "operator": "=",
        "operandRight": selectedAsset
      }
    };
    
    try {
      // Make the POST request to create a new contract definition
      const response = await axios.post(`${edc_management_url}/management/v3/contractdefinitions`, newContractDefinition, authHeaders);

      // Check if the response is successful
      if (response.status === 201||200) {
        const createdContract = response.data;
        alert('Contract definition created successfully!');
      //  onCreate(response.data); // Pass the new or updated asset back to the parent component
      onClose();
        // Pass the newly created contract definition back to the parent component
      //  onCreate(createdContract);
     // window.location.href = "/edc-management/contractdefinitions/request";
      } else {
        setError('Failed to create contract definition');
      }
    } catch (err) {
      console.error(err);
      setError('Error occurred while creating contract definition. Please check the Definition ID');
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
      <div className="form-group" style={{ marginBottom: '15px', textAlign: 'center' }}>
        <label htmlFor="definitionId" style={{ fontWeight: 'bold', display: 'block', color: 'white' }}>Definition ID</label>
        <input
          type="text"
          id="definitionId"
          value={definitionId}
          onChange={(e) => setDefinitionId(e.target.value)}
          style={{ padding: '10px', width: '76%', borderRadius: '5px', textAlign: 'center'}}
          required
        />
      </div>

      <div className="form-group" style={{ marginBottom: '15px', textAlign: 'center' }}>
        <label htmlFor="accessPolicyId" style={{ fontWeight: 'bold', display: 'block', color: 'white' }}>Access Policy</label>
        <select
          id="accessPolicyId"
          value={accessPolicyId}
          onChange={(e) => setAccessPolicyId(e.target.value)}
          style={{ padding: '10px', width: '80%', borderRadius: '5px', textAlign: 'center' }}
          required
        >
          <option value="">Select Access Policy</option>
          {policies.map((policy) => (
            <option key={policy["@id"]} value={policy["@id"]}>
              {policy["@id"]} - {policy.properties?.description || "No description available"}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group" style={{ marginBottom: '15px', textAlign: 'center' }}>
        <label htmlFor="contractPolicyId" style={{ fontWeight: 'bold', display: 'block', color: 'white' }}>Contract Policy</label>
        <select
          id="contractPolicyId"
          value={contractPolicyId}
          onChange={(e) => setContractPolicyId(e.target.value)}
          style={{ padding: '10px', width: '80%', borderRadius: '5px', textAlign: 'center' }}
          required
        >
          <option value="">Select Contract Policy</option>
          {policies.map((policy) => (
            <option key={policy["@id"]} value={policy["@id"]}>
              {policy["@id"]} - {policy.properties?.description || "No description available"}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group" style={{ marginBottom: '15px', textAlign: 'center' }}>
        <label htmlFor="assetsSelector" style={{ fontWeight: 'bold', display: 'block', color: 'white' }}>Assets Selector</label>
        <select
          id="assetsSelector"
          value={selectedAsset}
          onChange={(e) => setSelectedAsset(e.target.value)}
          style={{ padding: '10px', width: '80%', borderRadius: '5px', textAlign: 'center' }}
          required
        >
          <option value="">Select an Asset</option>
          {assets.map((asset) => (
            <option key={asset["@id"]} value={asset["@id"]}>
              {asset["@id"]} - {asset.properties?.description || "No description available"}
            </option>
          ))}
        </select>
      </div>

      <button type="submit">Create Contract Definition</button>

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </form>
  );
};

export default CreateContractDefinitionForm;
