import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Pie } from 'react-chartjs-2'; // Import Pie chart
import './ManagementList.css'; // Ensure this is the correct path to your CSS file
import config from '../config/config.json'; // Import your config
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'; // Import necessary Chart.js components
import Modal from './Modal'; // Import the Modal component
import { io } from 'socket.io-client'; // Import Socket.IO client

ChartJS.register(ArcElement, Tooltip, Legend); // Register the components

const edc_management_url = config.edc_management_url;
const apiKey = config.apiKey;
const edc_catalog_url = config.edc_catalog_url;
const dtr_url = config.dtr_url;
const bpn = config.bpn;
const socketUrl = config.backend_url; // Use your backend URL from config

const Board = () => {
  const [stats, setStats] = useState({
    assets: 0,
    policies: 0,
    contractDefinitions: 0,
    negotiations: 0,
    alltransfers: 0,
  });
  const [transferStats, setTransferStats] = useState({ completed: 0, terminated: 0 });
  const [terminatedNegotiations, setTerminatedNegotiations] = useState([]); // New state for terminated negotiations
  const [webhookData, setWebhookData] = useState([]); // State to hold webhook data
  const [selectedWebhook, setSelectedWebhook] = useState(null); // State for the selected webhook data
  const [selectedNegotiation, setSelectedNegotiation] = useState(null); // State for the selected negotiation
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize navigate

  const authHeaders = {
    headers: {
      'X-Api-Key': `${apiKey}`,
      'Content-Type': 'application/json'
    },
  };

  const jsonldBody = {
    "@context": {
      "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
    },
    "@type": "QuerySpec",
    "offset": 0,
    "limit": 200,
    "sortOrder": "DESC"
  };

  const authHeadersdtr = {
    headers: {
      'Edc-Bpn': `${bpn}`,
      'Content-Type': 'application/json'
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch counts of various entities
        const [assetsResponse, policiesResponse, contractDefinitionsResponse, negotiationsResponse, transfersResponse, dtrResponse] = await Promise.all([
          axios.post(`${edc_management_url}/management/v3/assets/request`, jsonldBody, authHeaders),
          axios.post(`${edc_management_url}/management/v3/policydefinitions/request`, jsonldBody, authHeaders),
          axios.post(`${edc_management_url}/management/v3/contractdefinitions/request`, jsonldBody, authHeaders),
          axios.post(`${edc_management_url}/management/v3/contractnegotiations/request`, jsonldBody, authHeaders),
          axios.post(`${edc_management_url}/management/v3/transferprocesses/request`, jsonldBody, authHeaders), // Fetch transfers
          axios.get(`${dtr_url}/semantics/registry/api/v3/shell-descriptors`, authHeadersdtr)
        ]);

        // Count transfers by status
        const transfers = transfersResponse.data;
        const completedCount = transfers.filter(transfer => transfer.state === 'COMPLETED').length;
        const terminatedCount = transfers.filter(transfer => transfer.state === 'TERMINATED').length;
        const requestedCount = transfers.filter(transfer => transfer.state === 'REQUESTED').length;
        const deprovisionedCount = transfers.filter(transfer => transfer.state === 'DEPROVISIONED').length;
        
        setTransferStats({ completed: completedCount, terminated: terminatedCount, requested: requestedCount, deprovisioned: deprovisionedCount });

        // Fetch terminated negotiations for the past 24 hours
        const negotiations = negotiationsResponse.data;
        const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000); // Calculate date 24 hours ago
        const filteredNegotiations = negotiations.filter(negotiation => {
          return negotiation.state === 'TERMINATED' && new Date(negotiation.createdAt) >= twentyFourHoursAgo;
        });

        setTerminatedNegotiations(filteredNegotiations);

        const catalogEntries = edc_catalog_url?.split(',') || [];
        
        // Log responses to debug
        console.log('Assets Response:', assetsResponse.data);
        console.log('Policies Response:', policiesResponse.data);
        console.log('Contract Definitions Response:', contractDefinitionsResponse.data);
        console.log('Negotiations Response:', negotiationsResponse.data);
        console.log('Transfers Response:', transfersResponse.data);
        console.log('Dtrs Response:', dtrResponse.data);
        console.log('Catalogs Response:', transfersResponse.data);

        // Assuming the responses are arrays, count their lengths
        setStats({
          assets: Array.isArray(assetsResponse.data) ? assetsResponse.data.length : 0,
          policies: Array.isArray(policiesResponse.data) ? policiesResponse.data.length : 0,
          contractDefinitions: Array.isArray(contractDefinitionsResponse.data) ? contractDefinitionsResponse.data.length : 0,
          negotiations: Array.isArray(negotiationsResponse.data) ? negotiationsResponse.data.length : 0,
          dtrs: Array.isArray(dtrResponse.data.result) ? dtrResponse.data.result.length : 0,
          alltransfers: Array.isArray(transfersResponse.data) ? transfersResponse.data.length : 0,
          catalogs: catalogEntries.length ? catalogEntries.length : 0,
        });

        // Fetch existing webhook data from the same /webhook endpoint
        const existingWebhookResponse = await axios.get(`${config.backend_url}/webhook`, authHeaders);
        setWebhookData(existingWebhookResponse.data.reverse()); // Reverse to show newest first

      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data. Please check the console for more details.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Establish WebSocket connection with the correct path
    const socket = io(config.backend_url, { path: '/socket/socket.io' });

    // Listen for new webhook data
    socket.on('webhookData', (data) => {
      console.log('New webhook data received:', data);
      setWebhookData((prevData) => [data, ...prevData]); // Add the new data to the beginning of the array
    });

    // Clean up on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleChartClick = () => {
    handleNavigate('/edc-management/transfer/request'); // Navigate to transfer request
  };

  const handleNegotiationClick = (negotiation) => {
    setSelectedNegotiation(negotiation); // Set selected negotiation to show in the modal
  };

  const handleNotificationAccepted = () => {
    setTerminatedNegotiations(prevNegotiations => 
      prevNegotiations.filter(negotiation => negotiation["@id"] !== selectedNegotiation["@id"])
    ); // Remove the accepted notification
    setSelectedNegotiation(null); // Close the modal
  };

  const handleWebhookClick = (data) => {
    setSelectedWebhook(data); // Set selected webhook data to show in the modal
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // Data for the pie chart
  const pieData = {
    labels: ['Completed', 'Terminated', 'Requested', 'Deprovisioned'],
    datasets: [
      {
        data: [transferStats.completed, transferStats.terminated, transferStats.requested, transferStats.deprovisioned],
        backgroundColor: ['#88f66f', '#d2d9d5', '#fec76f','#71a3c1'],
        hoverBackgroundColor: ['#88f66f', '#d2d9d5', '#fec76f', '#71a3c1']
      }
    ]
  };

  return (
    <div className="board">
      <div className="charts-container">
        <div className="left-chart">
          <br></br><br></br>
          <h2>Notification Panel</h2>
          <br></br>
          {terminatedNegotiations.length > 0 ? (
            <ul className="notification-list">
              {terminatedNegotiations.map((negotiation) => (
                <li key={negotiation["@id"]} onClick={() => handleNegotiationClick(negotiation)}>
                  <p><strong>From:</strong> {negotiation["counterPartyId"]}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No terminated negotiations in the last 24 hours.</p>
          )}
          <br></br><br></br>        
          <h2>Received Data</h2>
          <div className="received-data-pane">
            {webhookData.length > 0 ? (
              <ul className="notification-list">
                {webhookData.slice(0, 4).map((data, index) => ( // Limit to 5 records
                  <li key={index} onClick={() => handleWebhookClick(data)}>
                    <p><strong>Title:</strong> {data.title || index}</p> {/* Display unique ID or index */}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No received data available.</p>
            )}
            {/* Scrollable Container */}
            <div className="scroll-container">
              <ul className="notification-list">
                {webhookData.slice().map((data, index) => ( // Show additional records
                  <li key={index + 5} onClick={() => handleWebhookClick(data)}>
                    <p><strong>Title:</strong> {data.title || (index + 5)}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="right-chart">
          <div className="chart-title">
            <h2>Transfer Status</h2>
          </div>
          <div className="pie-chart">
            <Pie data={pieData} onClick={handleChartClick} />
          </div>
        </div>
      </div>
      <div className="board-stats">
        <div className="stat-box" onClick={() => handleNavigate('/edc-management/assets/request')}>
          <p className="stat-number">{stats.assets}</p>
          <h3>Assets</h3>
        </div>
        <div className="stat-box" onClick={() => handleNavigate('/edc-management/dtr')}>
          <p className="stat-number">{stats.dtrs}</p>
          <h3>Digital Twins</h3>
        </div>
        <div className="stat-box" onClick={() => handleNavigate('/edc-management/policydefinitions/request')}>
          <p className="stat-number">{stats.policies}</p>
          <h3>Policies</h3>
        </div>
        <div className="stat-box" onClick={() => handleNavigate('/edc-management/contractdefinitions/request')}>
          <p className="stat-number">{stats.contractDefinitions}</p>
          <h3>Contract Definitions</h3>
        </div>
        <div className="stat-box" onClick={() => handleNavigate('/edc-management/contractnegotiations/request')}>
          <p className="stat-number">{stats.negotiations}</p>
          <h3>Contract Negotiations</h3>
        </div>
        <div className="stat-box" onClick={() => handleNavigate('/edc-management/catalog/request')}>
          <p className="stat-number">{stats.catalogs}</p>
          <h3>Catalogs</h3>
        </div>
        <div className="stat-box" onClick={() => handleNavigate('/edc-management/transfer/request')}>
          <p className="stat-number">{stats.alltransfers}</p>
          <h3>Transfers</h3>
        </div>
      </div>
      
      {/* Modal for Negotiation Details */}
      <Modal isOpen={!!selectedNegotiation} onClose={() => setSelectedNegotiation(null)}>
        {selectedNegotiation && (
          <div>
            <h3>Negotiation Details</h3>
            <p><strong>ID:</strong> {selectedNegotiation["@id"]}</p>
            <p><strong>Type:</strong> {selectedNegotiation.type}</p>
            <p><strong>Counterparty ID:</strong> {selectedNegotiation.counterPartyId}</p>
            <p><strong>Counterparty Address:</strong> {selectedNegotiation.counterPartyAddress}</p>
            <p><strong>Error Detail:</strong> {selectedNegotiation.errorDetail || "No error details available."}</p>
            <button onClick={handleNotificationAccepted}>Notification Accepted</button>
          </div>
        )}
      </Modal>

      {/* Modal for Webhook Data Details */}
      <Modal isOpen={!!selectedWebhook} onClose={() => setSelectedWebhook(null)}>
        {selectedWebhook && (
          <div>
            <h3>Webhook Data Details</h3>
            <pre>{JSON.stringify(selectedWebhook, null, 2)}</pre>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Board;
