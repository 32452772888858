import React, { useState } from 'react';
import CreatePolicyDefinitionForm from '.././CreatePolicyDefinitionForm'; // Import the form component

const CreatePoliciesStep = ({ onCompletion }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleCreatePolicies = () => {
    // Open the form
    setIsFormOpen(true);
  };

  const handlePolicyCreated = (policyData) => {
    alert('Policy created successfully!'); // Alert on success
    onCompletion(policyData); // Proceed to the next step (contract definition)
    setIsFormOpen(false); // Close the form after completion
  };

  return (
    <div>
      <h3>Step 3: Create Policies</h3>
      <button onClick={handleCreatePolicies}>Create Policies</button>

      {isFormOpen && (
        <CreatePolicyDefinitionForm
          onCreate={handlePolicyCreated} // Call this when the policy is created
        />
      )}
    </div>
  );
};

export default CreatePoliciesStep;
