import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import AssetList from './components/AssetList';
import PolicyDefinitionsList from './components/PolicyDefinitionsList';
import './App.css';
import CatalogList from './components/CatalogList';
import NegotiationList from './components/NegotiationList';
import Board from './components/Board';
import ContractDefinitionList from './components/ContractDefinitionList';
import DtrList from './components/DtrList';
import TransferList from './components/TransferList';
import WebhookListener from './components/WebhookListener';
import { WebhookProvider } from './components/WebhookContext';
import UploadWizard from './components/wizard/UploadWizard';

function App() {
  return (
    <WebhookProvider>
    <Router>
      <div className="App">
        <Sidebar />
        <div className="content-area">
        <Routes>
           {/* Default route for Board */}
            <Route path="/" element={<Navigate to="/edc-management/board" />} />
            <Route path="/edc-management/board" element={<Board />} />
            <Route path="/edc-management/assets/request" element={<AssetList />} />
            <Route path="/edc-management/dtr" element={<DtrList />} />
            <Route path="/edc-management/policydefinitions/request" element={<PolicyDefinitionsList />} /> {/* Route added */}
            <Route path="/edc-management/catalog/request" element={<CatalogList />} />
            <Route path="/edc-management/contractnegotiations/request" element={<NegotiationList />} />
            <Route path="/edc-management/contractdefinitions/request" element={<ContractDefinitionList />} />
            <Route path="/edc-management/transfer/request" element={<TransferList />} />
            <Route path="/edc-management/WebhookListener" element={<WebhookListener />} />
            <Route path="/edc-management/UploadWizard" element={<UploadWizard />} />
          </Routes>
        </div>
      </div>
    </Router>
    </WebhookProvider>
  );
}

export default App;
