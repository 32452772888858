import React, { useState, useEffect } from 'react';
import './Modal.css'; // Use the updated modal styles
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // For generating UUID (in case of new asset)
import config from '../config/config.json';

const edc_management_url = config.edc_management_url;
const apiKey = config.apiKey;

const authHeaders = {
  headers: {
    'X-Api-Key': `${apiKey}`,
    'Content-Type': 'application/json'
  },
};

const CreateAssetForm = ({ asset, onCreate }) => {
  // States for form fields, initialized with empty values or asset details if available
  const [id, setId] = useState(asset ? asset["@id"] : uuidv4());
  const [assetName, setAssetName] = useState(asset?.properties?.name || '');
  const [description, setDescription] = useState(asset?.properties?.description || '');
  const [tags, setTags] = useState(
    asset?.privateProperties
      ? Object.entries(asset.privateProperties).map(([key, value]) => ({ key, value }))
      : [{ key: '', value: '' }]
  );
  const [addressType, setAddressType] = useState(asset?.dataAddress?.type || 'HttpData');
  const [url, setUrl] = useState(asset?.dataAddress?.baseUrl || '');
  const [s3Data, setS3Data] = useState({
    accessKey: asset?.dataAddress?.accessKey || '',
    secretKey: asset?.dataAddress?.secretKey || '',
    bucketName: asset?.dataAddress?.bucketName || ''
  });
  const [error, setError] = useState(null);

  // Handle tag input change
  const handleTagChange = (index, field, value) => {
    const newTags = [...tags];
    newTags[index][field] = value;
    setTags(newTags);
  };

  // Add new tag row
  const handleAddTag = () => {
    setTags([...tags, { key: '', value: '' }]);
  };

  // Delete tag row
  const handleDeleteTag = (index) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
  };

  // Handle form submission (both create and update)
  const handleSubmit = async (e) => {
    e.preventDefault();

    const privateProperties = {};
    tags.forEach(tag => {
      if (tag.key && tag.value) privateProperties[tag.key] = tag.value;
    });

    const assetData = {
      "@context": {
        "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
      },
      "@id": id,
      "properties": {
        "name": assetName,
        "description": description
      },
      "privateProperties": privateProperties,
      "dataAddress": {
        "type": addressType,
        ...(addressType === 'HttpData' && { baseUrl: url }),
        ...(addressType === 'S3' && {
          accessKey: s3Data.accessKey,
          secretKey: s3Data.secretKey,
          bucketName: s3Data.bucketName
        })
      }
    };

    try {
      const requestType = asset ? 'put' : 'post'; // If asset is passed, we update; otherwise, create
      const url = asset
        ? `${edc_management_url}/management/v3/assets` // Update URL
        : `${edc_management_url}/management/v3/assets`; // Create URL

      const response = await axios[requestType](url, assetData, authHeaders);
      alert(asset ? 'Asset updated successfully!' : 'Asset created successfully!');
      onCreate(response.data); // Pass the new or updated asset back to the parent component
    } catch (err) {
      setError('Failed to submit asset');
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
      <div className="form-group" style={{ marginBottom: '10px', marginTop: '15px', textAlign: 'center' }}>
        <label htmlFor="assetName" style={{ fontWeight: 'bold', color: 'white', marginRight: '15px' }}>Asset Name</label>
        <input
          type="text"
          id="assetName"
          value={assetName}
          onChange={(e) => setAssetName(e.target.value)}
          style={{ padding: '10px', width: '70%', borderRadius: '5px', textAlign: 'center' }}
          required
        />
      </div>

      <div className="form-group" style={{ marginBottom: '10px', marginTop: '15px', textAlign: 'center' }}>
        <label htmlFor="description" style={{ fontWeight: 'bold', color: 'white', marginRight: '15px' }}>Description</label>
        <input
          type="text"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ padding: '10px', width: '70%', borderRadius: '5px', textAlign: 'center' }}
          required
        />
      </div>

      <div className="form-group" style={{ marginBottom: '25px', marginTop: '25px', textAlign: 'center' }}>
        <label style={{ fontWeight: 'bold', color: 'white', marginRight: '15px' }}>Additional Tags</label>
        <button type="button" onClick={handleAddTag} style={{ borderRadius: '5px', textAlign: 'center', padding: '10px', width: '30%', marginRight: '15px' }}>Add Tag</button>
        {tags.map((tag, index) => (
          <div key={index} style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '10px' }}>
            <input
              type="text"
              placeholder="Key"
              value={tag.key}
              onChange={(e) => handleTagChange(index, 'key', e.target.value)}
              style={{ borderRadius: '5px', textAlign: 'center', padding: '10px', width: '30%', marginRight: '15px' }}
            />
            <input
              type="text"
              placeholder="Value"
              value={tag.value}
              onChange={(e) => handleTagChange(index, 'value', e.target.value)}
              style={{ borderRadius: '5px', textAlign: 'center', padding: '10px', width: '30%' }}
            />
            <button type="button" onClick={() => handleDeleteTag(index)} style={{ marginLeft: '15px' }}>Delete</button>
          </div>
        ))}
      </div>

      <hr />

      <div className="form-group" style={{ marginBottom: '15px', textAlign: 'right', marginTop: '25px' }}>
        <label htmlFor="addressType" style={{ fontWeight: 'bold', color: 'white', marginRight: '15px', borderRadius: '5px' }}>Address Type</label>
        <select
          id="addressType"
          value={addressType}
          onChange={(e) => setAddressType(e.target.value)}
          style={{ padding: '10px', width: '76%', borderRadius: '5px', textAlign: 'center' }}
          required
        >
          <option value="HttpData">HttpData</option>
          <option value="S3">S3</option>
          <option value="Handler">Handler</option>
        </select>
      </div>

      {addressType === 'HttpData' && (
        <div className="form-group" style={{ marginBottom: '15px', textAlign: 'right' }}>
          <label htmlFor="url" style={{ fontWeight: 'bold', color: 'white', marginRight: '15px' }}>URL</label>
          <input
            type="url"
            id="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            style={{ padding: '10px', width: '72%', borderRadius: '5px', textAlign: 'center' }}
            required
          />
        </div>
      )}

      {addressType === 'S3' && (
        <>
          <div className="form-group" style={{ marginBottom: '15px', textAlign: 'right' }}>
            <label htmlFor="accessKey" style={{ fontWeight: 'bold', color: 'white', marginRight: '15px' }}>Access Key</label>
            <input
              type="text"
              id="accessKey"
              value={s3Data.accessKey}
              onChange={(e) => setS3Data({ ...s3Data, accessKey: e.target.value })}
              style={{ padding: '10px', width: '72%', borderRadius: '5px', textAlign: 'center' }}
              required
            />
          </div>

          <div className="form-group" style={{ marginBottom: '15px', textAlign: 'right' }}>
            <label htmlFor="secretKey" style={{ fontWeight: 'bold', color: 'white', marginRight: '15px' }}>Secret Key</label>
            <input
              type="text"
              id="secretKey"
              value={s3Data.secretKey}
              onChange={(e) => setS3Data({ ...s3Data, secretKey: e.target.value })}
              style={{ padding: '10px', width: '72%', borderRadius: '5px', textAlign: 'center' }}
              required
            />
          </div>

          <div className="form-group" style={{ marginBottom: '15px', textAlign: 'right' }}>
            <label htmlFor="bucketName" style={{ fontWeight: 'bold', color: 'white', marginRight: '15px' }}>Bucket</label>
            <input
              type="text"
              id="bucketName"
              value={s3Data.bucketName}
              onChange={(e) => setS3Data({ ...s3Data, bucketName: e.target.value })}
              style={{ padding: '10px', width: '72%', borderRadius: '5px', textAlign: 'center' }}
              required
            />
          </div>
        </>
      )}

      <div style={{ marginTop: '30px' }}>
        <button type="submit" style={{ marginRight: '10px', width: '35%' }}>{asset ? 'Update' : 'Create'}</button>
        <button type="button" style={{ marginRight: '10px', width: '35%' }} onClick={() => window.location.reload()}>Cancel</button>
      </div>

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </form>
  );
};

export default CreateAssetForm;
