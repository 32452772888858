import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PolicyDefinitionDetails from './PolicyDefinitionDetails'; // The details component for policies
import CreatePolicyDefinitionForm from './CreatePolicyDefinitionForm'; // Form for creating new policy definitions
import Modal from './Modal'; // For creating new policy definitions in a popup
import './ManagementList.css'; // Use the combined CSS file
import config from '../config/config.json';

const PolicyDefinitionsList = () => {
  const [policyDefinitions, setPolicyDefinitions] = useState([]);
  const [selectedPolicyDefinition, setSelectedPolicyDefinition] = useState(null);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for the modal
  const [filterPolicyType, setFilterPolicyType] = useState(''); // Filter state for policy type

  const edc_management_url = config.edc_management_url;
  const apiKey = config.apiKey;
  const authHeaders = {
    headers: {
      'X-Api-Key': `${apiKey}`,
      'Content-Type': 'application/json',
    },
  };

  const jsonldBody = {
    "@context": {
      "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
    },
    "@type": "QuerySpec"
  };

  useEffect(() => {
    // Fetch policy definitions from the backend
    axios.post(`${edc_management_url}/management/v3/policydefinitions/request`, jsonldBody, authHeaders)
      .then((response) => {
        setPolicyDefinitions(response.data);
      })
      .catch((error) => setError('Error fetching policy definitions'));
  }, []);

  const addNewPolicyDefinition = (newPolicyDefinition) => {
    setPolicyDefinitions((prevPolicyDefinitions) => [...prevPolicyDefinitions, newPolicyDefinition]);
    setIsModalOpen(false); // Close modal after creation
  };

  // Filter the policy definitions based on the policy type
// Filter the policy definitions based on the policy type
const filteredPolicyDefinitions = policyDefinitions.filter((policyDefinition) => {
  const policyId = policyDefinition["@id"];
  if (filterPolicyType === 'ACCESS') {
      return policyId.startsWith('ACCESS_POLICY-');
  } else if (filterPolicyType === 'USAGE') {
      return policyId.startsWith('USAGE_POLICY-');
  } else if (filterPolicyType === 'CUSTOM') {
      return !policyDefinition.Properties?.policy_type; // Custom policy logic remains the same
  } else {
      return true; // If no filter is applied, return all
  }
});

  return (
    <div className="management-container">
      <div className="list-container">
        <h2>Policy Definitions List</h2>

        {/* Filter by Policy Type */}
        <div className="filter-container">
          <label htmlFor="policyTypeFilter">Filter by Policy Type:</label>
          <select
            id="policyTypeFilter"
            className="styled-select"
            value={filterPolicyType}
            onChange={(e) => setFilterPolicyType(e.target.value)}
          >
            <option value="">All</option>
            <option value="ACCESS">Access Policy</option>
            <option value="USAGE">Usage Policy</option>
            <option value="CUSTOM">Custom Policy</option>
          </select>
        </div>

        {error ? (
          <p>{error}</p>
        ) : (
          <div className="block-container">
            {filteredPolicyDefinitions.map((policyDefinition) => (
              <div
                key={policyDefinition["@id"]}
                className="block-item"
                onClick={() => setSelectedPolicyDefinition(policyDefinition)}
              >
                <p><strong>ID:</strong> {policyDefinition["@id"]}</p>
              </div>
            ))}
          </div>
        )}
        <div className="button-container">
          <button onClick={() => setIsModalOpen(true)}>Create Policy Definition</button>
        </div>
      </div>

      {/* Policy Definition Details Panel */}
      <div className="details-panel">
        {selectedPolicyDefinition ? (
          <PolicyDefinitionDetails
            policyDefinition={selectedPolicyDefinition}
            onDelete={() => setSelectedPolicyDefinition(null)}
            onUpdate={(updatedPolicyDefinition) => setSelectedPolicyDefinition(updatedPolicyDefinition)}
          />
        ) : (
          <p>Select a policy definition to view details</p>
        )}
      </div>

      {/* Modal for creating policy definitions */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <CreatePolicyDefinitionForm onCreate={addNewPolicyDefinition} />
      </Modal>
    </div>
  );
};

export default PolicyDefinitionsList;
