import React, { useState } from 'react';
import axios from 'axios';
import config from '../config/config.json';

const edc_management_url = config.edc_management_url;
const apiKey = config.apiKey;

const authHeaders = {
  headers: {
    'X-Api-Key': `${apiKey}`,
    'Content-Type': 'application/json'
  },
};

const TransferDetails = ({ transfer, onClose }) => {
  const [state, setState] = useState(null);
  const [error, setError] = useState(null);

  const fetchState = () => {
    axios.get(`${edc_management_url}/management/v3/transferprocesses/${transfer["@id"]}/state`, authHeaders)
      .then((response) => setState(response.data))
      .catch(() => setError('Error fetching state'));
  };

  const terminateTransfer = () => {
    axios.post(`${edc_management_url}/management/v3/transferprocesses/${transfer["@id"]}/terminate`, {
      reason: "Terminated by user request"
    }, authHeaders)
      .then(() => {
        setError(null);
        alert('Transfer terminated successfully');
        onClose();
      })
      .catch(() => setError('Error terminating transfer'));
  };

  return (
    <div className="asset-details">
      <h3>Transfer Details</h3>
      <p><strong>ID:</strong> {transfer["@id"]}</p>
      <p><strong>Counterparty Address:</strong> {transfer.counterPartyAddress || "N/A"}</p>
      <p><strong>Transfer Type:</strong> {transfer.transferType || "N/A"}</p>

      {/* Display fetched state if available */}
      {state && (
        <div>
          <h4>State</h4>
          <p>{JSON.stringify(state)}</p>
        </div>
      )}

      {/* Error handling */}
      {error && <p className="error">{error}</p>}

      {/* Buttons for checking state, termination, and closing */}
      <div className="button-container">
        <button onClick={fetchState}>Check State</button>
        <button onClick={terminateTransfer}>Terminate</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default TransferDetails;
