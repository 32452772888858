import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CatalogDetails from './CatalogDetails';
import './ManagementList.css'; // Use the combined CSS file
import config from '../config/config.json';

const edc_catalog_url = config.edc_catalog_url;
const edc_management_url = config.edc_management_url;
const apiKey=config.apiKey;
const bpn=config.bpn;

const CatalogList = () => {
  const [catalogUrls, setCatalogUrls] = useState([]);
  const [catalogs, setCatalogs] = useState([]); // Store both name and URL pairs
  const [selectedCatalogUrl, setSelectedCatalogUrl] = useState(null); // To track the selected catalog URL
  const [offers, setOffers] = useState([]);
  const [fullCatalog, setFullCatalog] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [error, setError] = useState(null);


  const authHeaders = {
    headers: {
      'X-Api-Key': `${apiKey}`,
      'Content-Type': 'application/json'
    },
  };
  const catalogRequestBody =  {
    "@context": {
      "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
    },
    "@type": "CatalogRequest",
    "counterPartyAddress": selectedCatalogUrl,
    "counterPartyId":  `${bpn}`,
    "protocol": "dataspace-protocol-http"
  };

  // Load catalog URLs from the environment variable when component mounts
  useEffect(() => {
    const catalogEntries  = edc_catalog_url?.split(',') || [];
   // Parse the entries into [{ name: 'Catalog1', url: 'URL1' }, { name: 'Catalog2', url: 'URL2' }]
    const parsedCatalogs = catalogEntries.map((entry) => {
    const [name, url] = entry.split('>');
    return { name, url };
  }); 
  setCatalogs(parsedCatalogs); // Set the available catalog URLs
  }, []);

  // Fetch the offers from the backend by passing the selected catalog URL
  useEffect(() => {
    if (selectedCatalogUrl) {
      axios
        .post(`${edc_management_url}/management/v3/catalog/request`, catalogRequestBody, authHeaders)
        .then((response) => {
          const catalogData = response.data;
          console.log('Catalog response:', catalogData);
        //  setOffers(catalogData['dcat:dataset'] || []);
        //  setFullCatalog(catalogData['dcat:dataset'] || []);
        const datasets = catalogData['dcat:dataset'];
        const normalizedOffers = Array.isArray(datasets) ? datasets : datasets ? [datasets] : [];
        setOffers(normalizedOffers);
        setFullCatalog(normalizedOffers);

        })
        .catch((error) => setError('Error fetching catalog offers'));
    }
  }, [selectedCatalogUrl]);

  const handleCatalogClick = (url) => {
    setSelectedCatalogUrl(url); // Set the selected catalog URL
    setError(null); // Reset error if any
    setOffers([]); // Clear offers when switching catalogs
  };

  const handleOfferClick = (offerId) => {
    if (!fullCatalog || !Array.isArray(fullCatalog)) {
      console.error('Full catalog is not available or not an array.');
      return;
    }

    const selected = fullCatalog.find((offer) => offer.id === offerId);
    if (selected) {
      setSelectedOffer(selected);
    } else {
      console.error('Offer not found in the full catalog.');
    }
  };

  return (
    <div className="management-container">
      {!selectedCatalogUrl ? (
        <div className="list-container">
          <h2>Select a Catalog</h2>
          {catalogs.length > 0 ? (
            <div className="block-container">
              {catalogs.map((catalog, index) => (
                <div
                  key={index}
                  className="block-item"
                  onClick={() => handleCatalogClick(catalog.url)}
                >
                  {/* Show the name instead of the URL */}
                  <p><strong>Catalog:</strong> {catalog.name}</p>
                </div>
              ))}
            </div>
          ) : (
            <p>No catalogs available. Please check your environment configuration.</p>
          )}
        </div>
      ) : (
        <div className="list-container">
          <h2>Catalog Offers</h2>
          <button onClick={() => setSelectedCatalogUrl(null)} style={{ marginBottom: '15px' }}>Back to Catalogs</button>
          {error ? (
            <p>{error}</p>
          ) : (
            <div className="block-container">
              {Array.isArray(offers) && offers.length > 0 ? (
                offers.map((offer) => (
                  <div
                    key={offer.id}
                    className="block-item"
                    onClick={() => handleOfferClick(offer.id)}
                  >
                    <p><strong>ID:</strong> {offer.id}</p>
                    <p><strong>Description:</strong> {offer.description || "No description available"}</p>
                  </div>
                ))
              ) : (
                <p>No offers available.</p>
              )}
            </div>
          )}
        </div>
      )}

      {/* Offer Details Panel */}
      {selectedOffer && (
        <div className="details-panel">
          <CatalogDetails
            offer={selectedOffer}
            selectedCatalogUrl={selectedCatalogUrl}  
            onClose={() => setSelectedOffer(null)}
          />
        </div>
      )}
    </div>
  );
};

export default CatalogList;