import config from '../../config/config.json';
import React from 'react';
import axios from 'axios';

const edc_management_url = config.edc_management_url;
const apiKey = config.apiKey;
const authHeaders = {
  headers: {
    'X-Api-Key': `${apiKey}`,
    'Content-Type': 'application/json'
  },
};

const CreateContractDefinitionsStep = ({ assets, policyId, onCompletion }) => {
  const handleCreateContracts = async () => {
    try {
      for (let asset of assets) {
        const definitionId = asset["@id"]; // Assuming you want to use the asset ID as the definition ID

        const contractData = {
          "@context": {
            "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
          },
          "@id": definitionId,
          "accessPolicyId": policyId, // Use the single policy ID
          "contractPolicyId": policyId, // Use the same single policy ID
          "assetsSelector": {
            "@type": "CriterionDto",
            "operandLeft": "https://w3id.org/edc/v0.0.1/ns/id",
            "operator": "=",
            "operandRight": asset["@id"] // Using the current asset ID
          }
        };

        await axios.post(`${edc_management_url}/management/v3/contractdefinitions`, contractData, authHeaders);
      }
      alert('Contracts created successfully!');
      onCompletion(); // Proceed to the next step
    } catch (err) {
      console.error('Error creating contracts:', err);
      alert('Error creating contracts.');
    }
  };

  return (
    <div>
      <h3>Step 4: Create Contract Definitions</h3>
      <button onClick={handleCreateContracts}>Create Contracts</button>
    </div>
  );
};

export default CreateContractDefinitionsStep;
