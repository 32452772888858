import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const ExcelUploadStep = ({ onFileUpload }) => {
  const [file, setFile] = useState(null);
  const [excelData, setExcelData] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    readExcel(file);
  };

  const readExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(jsonData);
      onFileUpload(jsonData); // Pass parsed data to the next step
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <h3>Step 1: Upload Excel File</h3>
      <input type="file" onChange={handleFileChange} />
      {excelData.length > 0 && (
        <div><h4>Data is correct</h4></div>
        /*<div>
          <h4>Preview Data</h4>
          <table>
            <thead>
              <tr>
                {Object.keys(excelData[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {excelData.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>*/
        
      )}
    </div>
  );
};

export default ExcelUploadStep;
