import React, { useState } from 'react';
import axios from 'axios';
import config from '../config/config.json';
import CreateAssetForm from './CreateAssetForm'; // Import the form
import Modal from './Modal'; // Import the modal component

const edc_management_url = config.edc_management_url;
const apiKey = config.apiKey;
const authHeaders = {
  headers: {
    'X-Api-Key': `${apiKey}`,
    'Content-Type': 'application/json',
  },
};

const AssetDetails = ({ asset, onDelete, onUpdate }) => {
  const [error, setError] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false); // State to show/hide update form

  // Function to delete the asset
  const deleteAsset = async () => {
    try {
      const response = await axios.delete(
        `${edc_management_url}/management/v3/assets/${asset["@id"]}`,
        authHeaders
      );
  
      // Check if a success message is returned in the response and display it
      if (response && response.data && response.data.message) {
        alert(response.data.message); // Assuming a message field in response
      } else {
        alert('Asset deleted successfully!');
      }
  
      onDelete(); // Call onDelete after successful deletion
    } catch (err) {
      console.error(err);
      
      // Check if the error response contains a message
      const errorMessage = err.response?.data?.[0]?.message || 'Failed to delete asset';
      setError(errorMessage);
    }
  };
  

  // Function to toggle the update form
  const openUpdateForm = () => {
    setShowUpdateForm(true); // Show the update form in a modal
  };

  return (
    <div className='asset-details'>
      <h2>Asset Details</h2>
      <p><strong>ID:</strong> {asset["@id"]}</p>
      <p><strong>Description:</strong> <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}> {asset.properties?.description || "No description available"}</pre></p>
      <p><strong>Properties:</strong> <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}> {JSON.stringify(asset.properties)}</pre></p>
      <p ><strong>Data Address:</strong>  <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{JSON.stringify(asset.dataAddress)}</pre></p>
      <p><strong>Base URL:</strong> <a href={asset.dataAddress?.baseUrl} target="_blank" rel="noopener noreferrer">{asset.dataAddress?.baseUrl}</a></p>
      <p><strong>Private Properties:</strong>  <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{JSON.stringify(asset.privateProperties)}</pre></p>
      <div className="button-container">
        <button  onClick={deleteAsset}>Delete</button>
        <button  onClick={openUpdateForm}>Update</button>
      </div>
      {error && <p>{error}</p>}

      <Modal isOpen={showUpdateForm} onClose={() => setShowUpdateForm(false)}>
        <CreateAssetForm 
          asset={asset} // Pass the selected asset as a prop to prepopulate the form
          onCreate={(updatedAsset) => {
            setShowUpdateForm(false); // Close the modal after successful update
            onUpdate(updatedAsset); // Handle the update callback
          }}
        />
      </Modal>
    </div>
  );
};

export default AssetDetails;
