import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Ensure this file is being properly linked
import config from '../config/config.json';

const backend_url = config.backend_url;
function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${backend_url}/users/login`, { username, password });

      // Store the JWT token in localStorage
      localStorage.setItem('token', response.data.token);
      // Navigate to the protected page after successful login
      navigate('/edc-management/board');
      
    } catch (err) {
      setError('Invalid credentials');
    }
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <img src="/images/polygran-logo.svg" alt="Polygran Logo" className="logo" />
        <h1>Welcome to EDC Management</h1>
      </div>

      <form className="login-form" onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        {error && <p className="error">{error}</p>}

        <button type="submit" className="login-button">Login</button>
      </form>
    </div>
  );
}

export default Login;
