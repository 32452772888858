import React from 'react';
import '.././ManagementList.css'; // Create a separate CSS file for styles
import sample_file from '../../sample_file.xlsx';

const ProcessInformation = () => {
  const steps = [
    {
      title: "Step 1: Select Excel File",
      description: "Upload an Excel file that contains the required fields for asset creation. Please ensure it is populated correctly. You can refer to the sample file provided below.",
    },
    {
      title: "Step 2: Create Assets",
      description: "Create the assets based on the data from the uploaded Excel file. Each asset will be generated according to the provided details.",
    },
    {
      title: "Step 3: Create Policy",
      description: "Create a policy for the assets based on the data in the Excel file. This policy will govern the access and usage of the assets.",
    },
    {
      title: "Step 4: Create Contract Definition",
      description: "Define contracts for the created assets and the policies from the previous steps. This ensures the agreements are formalized.",
    },
  ];

  return (
    <div className="process-info">
      <h2>Upload Process Overview</h2>
      <br></br>
      {steps.map((step, index) => (
        <div key={index} className="process-step">
          <h3>{step.title}</h3>
          <p>{step.description}</p>
        </div>
      ))}
      <div className="sample-file">
        <h4>Sample Excel File</h4>
        {/* Here you can add a link or button to download the sample file */}
        <a href={sample_file}  target="_blank" download>Download Sample Excel File</a>
      </div>
    </div>
  );
};

export default ProcessInformation;
