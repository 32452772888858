import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import AssetList from './components/AssetList';
import PolicyDefinitionsList from './components/PolicyDefinitionsList';
import './App.css';
import CatalogList from './components/CatalogList';
import NegotiationList from './components/NegotiationList';
import Board from './components/Board';
import ContractDefinitionList from './components/ContractDefinitionList';
import DtrList from './components/DtrList';
import TransferList from './components/TransferList';
import WebhookListener from './components/WebhookListener';
import { WebhookProvider } from './components/WebhookContext';
import UploadWizard from './components/wizard/UploadWizard';
import Login from './components/Login';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if token exists in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const PrivateRoute = ({ element }) => {
    return isAuthenticated ? element : <Navigate to="/login" />;
  };

  // Conditionally render Sidebar for non-login routes
  const AppContent = () => {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login';
    const showSidebar = location.pathname !== "/login"; // Show Sidebar only when not on login page

    return (
      <div className="App">
        {showSidebar && <Sidebar />} {/* Conditionally render Sidebar */}
      
        <div className={`Content ${isLoginPage ? 'Content-login' : ''}`}>

          <Routes>
            {/* Login Route */}
            
            <Route path="/login" element={<Login />} />
            
            {/* Protected Routes */}
            <Route path="/edc-management/board" element={<PrivateRoute element={<Board />} />} />
            <Route path="/edc-management/assets/request" element={<PrivateRoute element={<AssetList />} />} />
            <Route path="/edc-management/dtr" element={<PrivateRoute element={<DtrList />} />} />
            <Route path="/edc-management/policydefinitions/request" element={<PrivateRoute element={<PolicyDefinitionsList />} />} />
            <Route path="/edc-management/catalog/request" element={<PrivateRoute element={<CatalogList />} />} />
            <Route path="/edc-management/contractnegotiations/request" element={<PrivateRoute element={<NegotiationList />} />} />
            <Route path="/edc-management/contractdefinitions/request" element={<PrivateRoute element={<ContractDefinitionList />} />} />
            <Route path="/edc-management/transfer/request" element={<PrivateRoute element={<TransferList />} />} />
            <Route path="/edc-management/WebhookListener" element={<PrivateRoute element={<WebhookListener />} />} />
            <Route path="/edc-management/UploadWizard" element={<PrivateRoute element={<UploadWizard />} />} />

            {/* Default route */}
            <Route path="/" element={<Navigate to="/login" />} />
          </Routes>
          </div>
        </div>
 
    );
  };

  return (
    <WebhookProvider>
      <Router>
        <AppContent />
      </Router>
    </WebhookProvider>
  );
}

export default App;
