import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ContractDefinitionDetails from './ContractDefinitionDetails';
import CreateContractDefinitionForm from './CreateContractDefinitionForm';
import Modal from './Modal';
import './ManagementList.css'; // Use the combined CSS file
import config from '../config/config.json';

const edc_management_url = config.edc_management_url;
const apiKey=config.apiKey;

const authHeaders = {
  headers: {
    'X-Api-Key': `${apiKey}`,
    'Content-Type': 'application/json'
  },
};

const ContractDefinitionList = () => {
  const [contractDefinitions, setContractDefinitions] = useState([]);
  const [selectedContractDefinition, setSelectedContractDefinition] = useState(null);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state for Create Contract Definition Form

  const jsonldBody ={
    "@context": {
      "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
    },
    "@type": "QuerySpec"
  };
  useEffect(() => {
    // Fetch contract definitions from the backend
    axios.post(`${edc_management_url}/management/v3/contractdefinitions/request`, jsonldBody, authHeaders)
      .then((response) => {
        setContractDefinitions(response.data);
      })
      .catch((error) => setError('Error fetching contract definitions'));
  }, []);

  const addNewContractDefinition = (newContractDefinition) => {
    setContractDefinitions((prevDefinitions) => [...prevDefinitions, newContractDefinition]);
    setIsModalOpen(false); // Close the modal after creation
  };

  return (
    <div className="management-container">
      <div className="list-container">
        <h2>Contract Definitions List</h2>
        {error ? (
          <p>{error}</p>
        ) : (
          <div className="block-container">
            {contractDefinitions.map((definition) => (
              <div
                key={definition["@id"]}
                className="block-item"
                onClick={() => setSelectedContractDefinition(definition)}
              >
                <p><strong>Contract ID:</strong> {definition["@id"]}</p>
                <p><strong>Asset ID:</strong> {definition.assetsSelector.operandRight || "No description available"}</p>
              </div>
            ))}
           
          </div>
        )}
        <div className="button-container">
              <button onClick={() => setIsModalOpen(true)}>Create Contract Definition</button>
        </div>
      </div>

      {/* Contract Definition Details Panel */}
      <div className="details-panel">
        {selectedContractDefinition ? (
          <ContractDefinitionDetails
            definition={selectedContractDefinition}
            onDelete={() => setSelectedContractDefinition(null)}
            onUpdate={(updatedDefinition) => setSelectedContractDefinition(updatedDefinition)}
          />
        ) : (
          <h3>Select a contract definition to view details</h3>
        )}
      </div>

      {/* Modal for creating a new contract definition */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <CreateContractDefinitionForm onCreate={addNewContractDefinition} />
      </Modal>
    </div>
  );
};

export default ContractDefinitionList;
