import React from 'react';
import axios from 'axios';
import config from '../../config/config.json';

const edc_management_url = config.edc_management_url;
const apiKey = config.apiKey;
const authHeaders = {
  headers: {
    'X-Api-Key': `${apiKey}`,
    'Content-Type': 'application/json'
  },
};

const AutoCreateAssets = ({ excelData, onCompletion }) => {
  const handleCreateAssets = async () => {
    const createdAssets = []; // Initialize an array to store created assets

    try {
      for (let row of excelData) {
        const assetData = {
          "@context": { "@vocab": "https://w3id.org/edc/v0.0.1/ns/" },
          "@id": row.id,
          "properties": { "name": row.assetName, "description": row.description },
          "privateProperties": { /* Handle tags if present in excel */ },
          "dataAddress": { type: row.addressType, baseUrl: row.url }
        };
        
        // Await the response and push the created asset to the array
        const response = await axios.post(`${edc_management_url}/management/v3/assets`, assetData, authHeaders);
        createdAssets.push(response.data); // Assuming response.data contains the created asset details
      }
      alert('Assets created successfully!');
      onCompletion(createdAssets); // Pass the created assets to the parent component
    } catch (err) {
      console.error('Error creating assets:', err);
      alert('Error creating assets. Please check the console for more details.');
    }
  };

  return (
    <div>
      <h3>Step 2: Create Assets</h3>
      <button onClick={handleCreateAssets}>Create Assets from Excel</button>
    </div>
  );
};

export default AutoCreateAssets;
