import React, { useState } from 'react';
import axios from 'axios';
import config from '../config/config.json';

const edc_management_url = config.edc_management_url;
const apiKey = config.apiKey;

const authHeaders = {
  headers: {
    'X-Api-Key': `${apiKey}`,
    'Content-Type': 'application/json',
  },
};

const ContractDefinitionDetails = ({ definition, onDelete, onUpdate }) => {
  const [error, setError] = useState(null);

  // Function to delete the contract definition
  const deleteContractDefinition = async () => {
    try {
      await axios.delete(`${edc_management_url}/management/v2/contractdefinitions/${definition["@id"]}`, authHeaders);
      alert('Contract definition deleted successfully!');
      onDelete();
    } catch (err) {
      const errorMessage = err.response?.data?.[0]?.message || 'Failed to delete contract definition';
      setError(errorMessage);
      alert('Contract definition deletion failed!');
    }
  };

  // Function to update the contract definition
  const updateContractDefinition = async () => {
    const updatedData = {
      "@context": {
        "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
      },
      "@id": definition["@id"],
      // Include properties you want to update, e.g.:
      "accessPolicyId": definition.accessPolicyId, // Example of keeping existing values
      "contractPolicyId": definition.contractPolicyId,
      "assetsSelector": definition.assetsSelector // Assuming you want to update this
    };

    try {
      const response = await axios.put(`${edc_management_url}/management/v2/contractdefinitions`, updatedData, authHeaders);
      alert('Contract definition updated successfully!');
      onUpdate(response.data);
    } catch (err) {
      setError('Failed to update contract definition');
    }
  };

  return (
    <div className="asset-details">
      <h2>Contract Definition Details</h2>
      <p><strong>ID:</strong> {definition["@id"]}</p>
      <p><strong>Access Policy ID:</strong> {definition.accessPolicyId || "No access policy defined"}</p>
      <p><strong>Contract Policy ID:</strong> {definition.contractPolicyId || "No contract policy defined"}</p>
      
      <h4>Assets Selector</h4>
      {definition.assetsSelector ? (
        <div>
          <p><strong>Operand Left:</strong> {definition.assetsSelector.operandLeft}</p>
          <p><strong>Operator:</strong> {definition.assetsSelector.operator}</p>
          <p><strong>Operand Right:</strong> {definition.assetsSelector.operandRight}</p>
        </div>
      ) : (
        <p>No assets selector defined</p>
      )}

      <div className="button-container">
        <button onClick={deleteContractDefinition}>Delete</button>
    {/*     <button onClick={updateContractDefinition}>Update</button>*/}
        </div>
      {error && <p>{error}</p>}
    </div>
  );
};

export default ContractDefinitionDetails;
