import React from 'react';
import axios from 'axios';
import config from '../config/config.json';

const edc_management_url = config.edc_management_url;
const apiKey = config.apiKey;
const bpn=config.bpn;




const authHeaders = {
  headers: {
    'X-Api-Key': `${apiKey}`,
    'Content-Type': 'application/json'
  },
};

const CatalogDetails = ({ offer, selectedCatalogUrl, onClose }) => {
  // Extract policyId and assetId from the offer
  const policyId = offer['odrl:hasPolicy']['@id'];
  const assetId = offer['@id'].split('/').pop(); // Assuming assetId is the last segment of the offer ID
const requestBody1=
{
	"@context": [
        "http://www.w3.org/ns/odrl.jsonld",
        "https://w3id.org/tractusx/policy/v1.0.0",
        {
          "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
        }
      ],
	"@type": "NegotiationInitiateRequestDto",
	"protocol": "dataspace-protocol-http",
    "counterPartyAddress": selectedCatalogUrl,
    "counterPartyId": bpn,
	"policy": {
        "@context" : "http://www.w3.org/ns/odrl.jsonld",
        "@id": policyId,
                "@type": "odrl:Offer",
                "odrl:permission": {
                    "odrl:action": {
                        "@id": "USE"
                    },
                    "odrl:constraint": {
                            "odrl:leftOperand": {
                                "@id": "BusinessPartnerNumber"
                            },
                            "odrl:operator": {
                                "@id": "odrl:eq"
                            },
                            "odrl:rightOperand": bpn                    
                    }
                },
                "odrl:prohibition": [],
                "odrl:obligation": [],
                "odrl:assigner": {"@id": bpn},
                "odrl:target": {"@id": assetId}

	}
}

  const requestBody = {
    "@context": {
      "@vocab": "https://w3id.org/edc/v0.0.1/ns/",
    },
    "@type": "ContractRequest",
    "counterPartyAddress": selectedCatalogUrl,
    "protocol": "dataspace-protocol-http",
    "policy": {
      "@context": "http://www.w3.org/ns/odrl.jsonld",
      "@type": "odrl:Offer",
      "@id": policyId,
      "assigner": bpn,
      "permission": [],
      "prohibition": [],
      "obligation": [],
      "target": assetId,
    },
  };




  const handleInitiateNegotiation = async (policyId, assetId) => {
    try {
      const response = await axios.post(`${edc_management_url}/management/v3/contractnegotiations`, requestBody1, authHeaders );
      alert('Negotiation initiated successfully:', response.data);
      // Handle success, maybe update UI or notify the user
    } catch (error) {
      alert('Error initiating negotiation:', error);
      // Handle error, maybe notify the user
    }
  };

  return (
    <div className="catalog-details">
      <h3>Offer Details</h3>
      <p><strong>ID:</strong> {offer.id}</p>
      <p><strong>Description:</strong> {offer.description || "No description available"}</p>
      <p><strong>Details:</strong> {JSON.stringify(offer.details) || "No additional details"}</p>
      <p><strong>Policy ID:</strong> {policyId}</p>
      <p><strong>Endpoint URL:</strong> {offer['dcat:distribution'][0]['dcat:accessService']['dct:endpointUrl']}</p>

      {/* Initiate Negotiation button */}
      <div className="button-container">
      <button onClick={() => handleInitiateNegotiation(policyId, assetId)}>Initiate Negotiation</button>
      {/* Close button */}
      <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default CatalogDetails;
