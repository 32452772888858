import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { WebhookContext } from './WebhookContext';
import io from 'socket.io-client';
import './Sidebar.css'; // Ensure CSS updates match
import config from '../config/config.json';

const socket = io(config.backend_url, { path: '/socket/socket.io'}); // Connect to the backend WebSocket

const Sidebar = () => {
  const { newDataReceived, setNewDataReceived, setWebhookData } = useContext(WebhookContext);

  useEffect(() => {
    // Listen for 'webhookData' event from the backend
    socket.on('webhookData', (data) => {
      console.log('New webhook data received');
      setWebhookData(data); // Store the new webhook data globally
      setNewDataReceived(true); // Mark that new data has been received
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.off('webhookData');
    };
  }, [setWebhookData, setNewDataReceived]);

  // Reset the notification when "Received data" is clicked
  const handleReceivedDataClick = () => {
    setNewDataReceived(false); // Reset the notification
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <a href="https://www.polygran.de" target="_blank" rel="noopener noreferrer">
          <img src="/images/polygran-favicon.ico" alt="Polygran Logo" className="favicon" />
        </a>
        <h2>Polygran GmbH</h2>
        <h3>EDC Management</h3>
      </div>
      <br />
      <ul>
        <li>
          <Link to="/edc-management/board">Board</Link>
        </li>
      </ul>

      <h3>Share Data</h3>
      <hr />
      <ul>
      <li>
          <Link to="/edc-management/uploadwizard">Upload Wizard</Link>
        </li>
        <li>
          <Link to="/edc-management/assets/request">Assets</Link>
        </li>
        <li>
          <Link to="/edc-management/dtr">Digital Twin Registry</Link>
        </li>
        <li>
          <Link to="/edc-management/policydefinitions/request">Policy Definitions</Link>
        </li>
        <li>
          <Link to="/edc-management/contractdefinitions/request">Contract Definitions</Link>
        </li>

        <h3>Consume Data</h3>
        <hr />
        <li>
          <Link to="/edc-management/catalog/request">Catalog</Link>
        </li>
        <li>
          <Link to="/edc-management/contractnegotiations/request">Contract Negotiations</Link>
        </li>
        <li>
          <Link to="/edc-management/transfer/request">Transfer</Link>
        </li>
        <li>
          <Link
            to="/edc-management/WebhookListener"
            onClick={handleReceivedDataClick}
            className={newDataReceived ? 'flash' : ''}
          >
            Received data {newDataReceived && <span className="new-data-notification"></span>}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
