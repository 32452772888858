import React, { useState } from 'react';
import axios from 'axios';
import config from '../config/config.json';
import CreatePolicyDefinitionForm from './CreatePolicyDefinitionForm'; // Import the form for updating policies
import Modal from './Modal'; // Modal for updating policy definitions

const PolicyDefinitionDetails = ({ policyDefinition, onDelete, onUpdate }) => {
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const [updatedPolicy, setUpdatedPolicy] = useState(policyDefinition); // State for the updated policy

  const { policy } = policyDefinition;
  const permissions = policy?.['odrl:permission'] || []; // Default to empty array if undefined
  const actionList = Array.isArray(permissions) ? permissions : [permissions]; // Ensure we have an array

  const edc_management_url = config.edc_management_url;
  const apiKey = config.apiKey;
  const authHeaders = {
    headers: {
      'X-Api-Key': `${apiKey}`,
      'Content-Type': 'application/json',
    },
  };

  // Function to delete the policy definition
  const deletePolicyDefinition = async () => {
    try {
      await axios.delete(`${edc_management_url}/management/v3/policydefinitions/${policyDefinition["@id"]}`, authHeaders);
      alert('Policy definition deleted successfully!');
      onDelete(); // Notify parent about deletion
    } catch (err) {
      const errorMessage = err.response?.data?.[0]?.message || 'Failed to delete the policy';
      setError(errorMessage);
    }
  };

  // Function to handle the update submission
  const handleUpdatePolicy = async (updatedData) => {
    try {
      const response = await axios.put(`${edc_management_url}/management/v3/policydefinitions/${policyDefinition["@id"]}`, updatedData, authHeaders);
      alert('Policy definition updated successfully!');
      onUpdate(response.data); // Notify parent about update
      setIsModalOpen(false); // Close the modal
    } catch (err) {
      setError('Failed to update policy definition');
    }
  };

  return (
    <div className="policydefinition-details">
      <h3>Policy Definition Details</h3>
      <p><strong>ID:</strong> {policyDefinition["@id"]}</p>
      <p><strong>Created At:</strong> {new Date(policyDefinition.createdAt).toLocaleString()}</p>

      <h4>Policy</h4>
      <p><strong>Policy ID:</strong> {policy["@id"]}</p>

      <h4>Permissions</h4>
      {actionList.map((perm, index) => {
        const action = perm['odrl:action']?.['@id'];
        const constraint = perm['odrl:constraint'];

        // Normalize constraints to ensure we can map over them
        let constraintsArray = [];
        if (constraint) {
          if (Array.isArray(constraint)) {
            constraintsArray = constraint; // If it's already an array, use it
          } else if (constraint['odrl:and']) {
            // If it's an object with 'odrl:and', treat its value as an array
            constraintsArray = constraint['odrl:and'];
          } else {
            // If it's a single constraint object, wrap it in an array
            constraintsArray = [constraint];
          }
        }

        return (
          <div key={index}>
            <p><strong>Action:</strong> {action}</p>
            <h5>Constraints</h5>
            {constraintsArray.map((c, idx) => (
              <div key={idx}>
                <p><strong>Left Operand:</strong> {c["odrl:leftOperand"]?.['@id']}</p>
                <p><strong>Operator:</strong> {c["odrl:operator"]?.['@id']}</p>
                <p><strong>Right Operand:</strong> {c["odrl:rightOperand"]}</p>
              </div>
            ))}
          </div>
        );
      })}

      <div className="button-container">
        <button onClick={deletePolicyDefinition}>Delete</button>
      {/*  <button onClick={() => { 
          setUpdatedPolicy(policyDefinition); // Load current policy into state for editing
          setIsModalOpen(true); // Open the modal for updating
        }}>Update</button>*/}
      </div>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Modal for updating the policy */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <CreatePolicyDefinitionForm 
          onCreate={handleUpdatePolicy} 
          asset={updatedPolicy} // Pass the current policy as the asset
        />
      </Modal>
    </div>
  );
};

export default PolicyDefinitionDetails;
