import React, { useState } from 'react';
import Modal from '.././Modal';
import ProcessInformation from './ProcessInformation'; // Import the process information component
import ExcelUploadStep from './ExcelUploadStep';
import AutoCreateAssets from './AutoCreateAssets';
import CreatePoliciesStep from './CreatePoliciesStep';
import CreateContractDefinitionsStep from './CreateContractDefinitionsStep';

const UploadWizard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [assets, setAssets] = useState([]);
  const [policyId, setPolicyId] = useState('');
  const [currentStep, setCurrentStep] = useState(0); // State to track the current step
  const [summary, setSummary] = useState({ assetsCount: 0, policiesCount: 0, contractsCount: 0 });

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setCurrentStep(0); // Reset to the first step when opening the modal
    setSummary({ assetsCount: 0, policiesCount: 0, contractsCount: 0 }); // Reset summary on opening
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentStep(0); // Reset step when closing the modal
  };

  const handleFileUpload = (data) => {
    setExcelData(data);
    setCurrentStep(1); // Move to the next step
  };

  const handleAssetsCreated = (createdAssets) => {
    setAssets(createdAssets);
    setSummary((prev) => ({ ...prev, assetsCount: createdAssets.length })); // Update assets count
    setCurrentStep(2); // Move to the next step
  };

  const handlePoliciesCreated = (policyData) => {
    setPolicyId(policyData.policyId);
    setSummary((prev) => ({ ...prev, policiesCount: 1 })); // Assuming one policy is created
    setCurrentStep(3); // Move to the next step
  };

  const handleContractsCreated = () => {
    setSummary((prev) => ({ ...prev, contractsCount: assets.length })); // Assuming one contract per asset
    setCurrentStep(4); // Move to the summary step
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return <ExcelUploadStep onFileUpload={handleFileUpload} />;
      case 1:
        return <AutoCreateAssets excelData={excelData} onCompletion={handleAssetsCreated} />;
      case 2:
        return <CreatePoliciesStep assets={assets} onCompletion={handlePoliciesCreated} />;
      case 3:
        return (
          <CreateContractDefinitionsStep
            assets={assets}
            policyId={policyId}
            onCompletion={handleContractsCreated}
          />
        );
      case 4: // Summary step
        return renderSummary(); // Show summary after all steps
      default:
        return null; // Fallback
    }
  };

  const renderSummary = () => (
    <div>
      <h2>Upload Summary</h2>
      <p>Created Assets: {summary.assetsCount}</p>
      <p>Created Policies: {summary.policiesCount}</p>
      <p>Created Contract Definitions: {summary.contractsCount}</p>
      <button onClick={handleCloseModal}>Finish</button> {/* Finish button to close the modal */}
    </div>
  );

  return (
    <div className='board'>
  <br></br>
  <br></br>
  <br></br>
  <br></br>
  <br></br>
  <br></br>
  <br></br>
      {/* Show Process Information here */}
      <ProcessInformation />

      {/* Button to start the upload process */}
      <center><button style={{ maxWidth: '30%' }}  className='button-container' onClick={handleOpenModal}>Start Upload Process</button></center>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div>
          {renderCurrentStep()} {/* Render the current step based on the current step number */}
          {/* Navigation buttons */}
          <div style={{ marginTop: '50px' }}>
            {currentStep > 0 && (
              <button onClick={() => setCurrentStep(currentStep - 1)} style={{ width: '35%' }}>Previous</button>
            )}
            {currentStep < 4 ? ( // Change this condition based on the number of steps
              <button onClick={() => setCurrentStep(currentStep + 1)} style={{ width: '35%' }}>Next</button>
            ) : null} {/* No next button on the summary step */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UploadWizard;
