import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NegotiationDetails from './NegotiationDetails'; // The details component for negotiations
import './ManagementList.css'; // Use the combined CSS file
import config from '../config/config.json';

const edc_management_url = config.edc_management_url;
const apiKey = config.apiKey;

const authHeaders = {
  headers: {
    'X-Api-Key': `${apiKey}`,
    'Content-Type': 'application/json'
  },
};

const NegotiationList = () => {
  const [negotiations, setNegotiations] = useState([]);
  const [uniqueCounterPartyAddresses, setUniqueCounterPartyAddresses] = useState([]);
  const [selectedNegotiation, setSelectedNegotiation] = useState(null);
  const [error, setError] = useState(null);
  const [filterState, setFilterState] = useState(''); // State filter
  const [filterCounterPartyAddress, setFilterCounterPartyAddress] = useState(''); // Counterparty address filter
  const [filterCreatedAt, setFilterCreatedAt] = useState(''); // Created date filter

  const jsonldBody = {
    "@context": {
      "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
    },
    "@type": "QuerySpec",
    "offset": 0,
    "limit": 200,
    "sortOrder": "DESC"
  };

  useEffect(() => {
    // Fetch all contract negotiations from the backend
    axios.post(`${edc_management_url}/management/v3/contractnegotiations/request`, jsonldBody, authHeaders)
      .then((response) => {
        setNegotiations(response.data);

        // Extract unique counterPartyAddress values
        const uniqueAddresses = [...new Set(response.data.map((negotiation) => negotiation.counterPartyAddress))];
        setUniqueCounterPartyAddresses(uniqueAddresses);
      })
      .catch((error) => setError('Error fetching contract negotiations'));
  }, []);

  // Helper function to check if a created date matches the filter
  const matchesCreatedAtFilter = (negotiationCreatedAt) => {
    if (!filterCreatedAt) return true;
    const filterDate = new Date(filterCreatedAt).setHours(0, 0, 0, 0); // Filter date set to start of day
    const createdDate = new Date(negotiationCreatedAt).setHours(0, 0, 0, 0);
    return createdDate === filterDate;
  };

  // Filter negotiations based on the selected filters
  const filteredNegotiations = negotiations.filter((negotiation) => {
    const matchesState = filterState ? negotiation.state === filterState : true;
    const matchesCounterParty = filterCounterPartyAddress
      ? negotiation.counterPartyAddress === filterCounterPartyAddress
      : true;
    const matchesCreatedAt = filterCreatedAt ? matchesCreatedAtFilter(negotiation.createdAt) : true;

    return matchesState && matchesCounterParty && matchesCreatedAt;
  });

  return (
    <div className="management-container">
      <div className="list-container">
        <h2>Contract Negotiations List</h2>

        <div className="filter-container">
          <div>
            <label htmlFor="stateFilter">State:</label>
            <select
              id="stateFilter"
              className="styled-select"
              value={filterState}
              onChange={(e) => setFilterState(e.target.value)}
            >
              <option value="">All</option>
              <option value="REQUESTED">Requested</option>
              <option value="TERMINATED">Terminated</option>
              <option value="FINALIZED">Finalized</option>
            </select>&nbsp;&nbsp;&nbsp;&nbsp;
            <label htmlFor="createdAtFilter">Created on:</label>
            <input
              id="createdAtFilter"
              className="styled-input"
              type="date"
              value={filterCreatedAt}
              onChange={(e) => setFilterCreatedAt(e.target.value)}
            />
          </div>          

            <label htmlFor="addressFilter">EDC Address:</label>
            <select
              id="addressFilter"
              className="styled-select"
              value={filterCounterPartyAddress}
              onChange={(e) => setFilterCounterPartyAddress(e.target.value)}
            >
              <option value="">All</option>
              {uniqueCounterPartyAddresses.map((address) => (
                <option key={address} value={address}>
                  {address}
                </option>
              ))}
            </select>
          </div>
        {error ? (
          <p>{error}</p>
        ) : (
          <div className="block-container-contract">
            
            {filteredNegotiations.map((negotiation) => (
              <div
                key={negotiation["@id"]}
                className="block-item"
                onClick={() => setSelectedNegotiation(negotiation)}
              >
                <p><strong>ID:</strong> {negotiation["@id"]}</p>
                <p><strong>State:</strong> {negotiation.state || "Unknown"}</p>
                <p><strong>Counterparty Address:</strong> {negotiation.counterPartyAddress}</p>
                <p><strong>Created At:</strong> {new Date(negotiation.createdAt).toLocaleDateString()}</p>
              </div>
            ))}
          </div>
        )}
     
      </div>

      {/* Contract Negotiation Details Panel */}
      <div className="details-panel">
        {selectedNegotiation ? (
          <NegotiationDetails
            negotiation={selectedNegotiation}
            onClose={() => setSelectedNegotiation(null)}
          />
        ) : (
          <p>Select a negotiation to view details</p>
        )}
      </div>
    </div>
  );
};

export default NegotiationList;
