import React, { useState } from 'react';
import './Modal.css';
import axios from 'axios';
import config from '../config/config.json';
import { v4 as uuidv4 } from 'uuid'; // Importing uuid function

const edc_management_url = config.edc_management_url;
const apiKey = config.apiKey;

const authHeaders = {
  headers: {
    'X-Api-Key': `${apiKey}`,
    'Content-Type': 'application/json',
  },
};

const CreatePolicyDefinitionForm = ({ onCreate, asset }) => {
  const [policyType, setPolicyType] = useState(''); // State for selecting Access or Usage Policy
  const [selectedSubPolicies, setSelectedSubPolicies] = useState([]); // State for multiple sub-policies
  const [id, setId] = useState(asset ? asset["@id"] : ''); // ID initialization
  const [bpnNumber, setBpnNumber] = useState(''); // For BusinessPartner Number Policy
  const [mstatus, setMembershipstatus] = useState(''); // For Active Membership Policy
  const [frameworkAgreement, setFrameworkAgreement] = useState(''); // For Framework Agreement Policy
  const [dateFrom, setDateFrom] = useState(''); // For InForce Policy Date From
  const [dateTo, setDateTo] = useState(''); // For InForce Policy Date To
  const [fixedDays, setFixedDays] = useState(''); // For InForce Fixed Days
  const [dismantlerStatus, setDismantlerStatus] = useState(''); // For Dismantler Policy
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubPolicyChange = (e) => {
    const value = e.target.value;
    if (selectedSubPolicies.includes(value)) {
      setSelectedSubPolicies(selectedSubPolicies.filter((subPolicy) => subPolicy !== value));
    } else {
      setSelectedSubPolicies([...selectedSubPolicies, value]);
    }
  };

  const buildPolicyDefinition = (policyId) => {
    const basePolicy = {
        "@context": [
            "http://www.w3.org/ns/odrl.jsonld",
            "https://w3id.org/tractusx/policy/v1.0.0",
            {
                "@vocab": "https://w3id.org/edc/v0.0.1/ns/"
            }
        ],
        "@type": "PolicyDefinition",
        "@id": policyId, // Use the passed ID here
        "policy": {
            "@type": "odrl:Set",
            "permission": []
        }
    };

    // Build the permission part based on each selected sub-policy
    selectedSubPolicies.forEach((subPolicy) => {
        let permission = {
            "@type": "odrl:permission",
            "odrl:action": {
                "@id": "USE"  // Removed 'edc:' prefix from the action
            },
            "odrl:constraint": []  // Initialize odrl.constraint as an empty array
        };

        // Add constraints based on the selected sub-policies (same logic as before)
        if (subPolicy === 'businessPartnerNumber') {
            permission["odrl:constraint"].push({
                "odrl:leftOperand": "BusinessPartnerNumber",  // No 'edc:' prefix
                "odrl:operator": {
                    "@id": "odrl:eq"  // Use correct operator format with @id
                },
                "odrl:rightOperand": bpnNumber
            });
        } else if (subPolicy === 'activeMembership') {
            permission["odrl:constraint"].push({
                "odrl:leftOperand": "ActiveMembership",  // No 'edc:' prefix
                "odrl:operator": {
                    "@id": "odrl:eq"  // Use correct operator format with @id
                },
                "odrl:rightOperand": mstatus
            });
        } else if (subPolicy === 'dismantlerPolicy') {
            permission["odrl:constraint"].push({
                "odrl:leftOperand": "DismantlerStatus",  // No 'edc:' prefix
                "odrl:operator": {
                    "@id": "odrl:eq"  // Use correct operator format with @id
                },
                "odrl:rightOperand": dismantlerStatus
            });
        } else if (subPolicy === 'frameworkAgreement') {
            permission["odrl:constraint"].push({
                "odrl:leftOperand": "FrameworkAgreement",  // No 'edc:' prefix
                "odrl:operator": {
                    "@id": "odrl:eq"  // Use correct operator format with @id
                },
                "odrl:rightOperand": frameworkAgreement
            });
        } else if (subPolicy === 'inForcePolicyDuration') {
            permission["odrl:constraint"].push({
                "odrl:and": [
                    {
                        "odrl:leftOperand": "InForceDate",
                        "odrl:operator": {
                            "@id": "odrl:gteq"  // Correct operator for greater than or equal
                        },
                        "odrl:rightOperand": {
                            "@value": `contractAgreement+0s`,
                            "@type": "dateExpression"
                        }
                    },
                    {
                        "odrl:leftOperand": "InForceDate",
                        "odrl:operator": {
                            "@id": "odrl:lteq"  // Correct operator for less than or equal
                        },
                        "odrl:rightOperand": {
                            "@value": `contractAgreement+${fixedDays}d`,
                            "@type": "dateExpression"
                        }
                    }
                ]
            });
        } else if (subPolicy === 'inForcePolicyFixed') {
            permission["odrl:constraint"].push({
                "odrl:and": [
                    {
                        "odrl:leftOperand": "InForceDate",
                        "odrl:operator": {
                            "@id": "odrl:gteq"  // Correct operator for greater than or equal
                        },
                        "odrl:rightOperand": {
                            "@value": dateFrom,
                            "@type": "xsd:datetime"
                        }
                    },
                    {
                        "odrl:leftOperand": "InForceDate",
                        "odrl:operator": {
                            "@id": "odrl:lteq"  // Correct operator for less than or equal
                        },
                        "odrl:rightOperand": {
                            "@value": dateTo,
                            "@type": "xsd:datetime"
                        }
                    }
                ]
            });
        }

        basePolicy.policy.permission.push(permission); // Append each permission
    });

    return basePolicy;
};

const handleSubmit = async (e) => {
  e.preventDefault();
  setError(null);
  setSuccessMessage('');

  // Generate new ID based on selected policy type
  const prefix = policyType === 'access' ? 'ACCESS_POLICY-' : 'USAGE_POLICY-';
  const newId = `${prefix}${uuidv4()}`; // Generate new ID with prefix

  // Build the policy definition using the newly generated ID
  const newPolicyDefinition = buildPolicyDefinition(newId); // Pass the new ID to the builder function

  try {
      const response = await axios.post(`${edc_management_url}/management/v3/policydefinitions`, newPolicyDefinition, authHeaders);
      if (response.status !== 200) {
          throw new Error('Failed to submit the policy definition');
      }
      setSuccessMessage('Policy definition created successfully!');
      onCreate({ newPolicyDefinition, policyId: newId }); // Use the newly generated ID
  } catch (err) {
      setError('Failed to create policy definition');
  }
};


  return (
    <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
      {/* Policy Type Selection */}
      <div className="form-group" style={{ marginBottom: '15px', textAlign: 'center' }}>
        <label htmlFor="policyType" style={{ fontWeight: 'bold', display: 'block' }}>
          Select Policy Type
        </label>
        <select
          id="policyType"
          value={policyType}
          onChange={(e) => {
            setPolicyType(e.target.value);
            setSelectedSubPolicies([]); // Reset sub-policy when type changes
            setId(''); // Reset ID when type changes
          }}
          style={{ padding: '10px', width: '80%', borderRadius: '5px', textAlign: 'center' }}
          required
        >
          <option value="">-- Select Policy Type --</option>
          <option value="access">Access Policy</option>
          <option value="usage">Usage Policy</option>
        </select>
      </div>

      {/* Sub-Policy Checkboxes */}
      {policyType && (
        <div className="form-group" style={{ marginBottom: '15px', textAlign: 'center' }}>
          <label htmlFor="selectedSubPolicies" style={{ fontWeight: 'bold', display: 'block' }}>
            Select Sub-Policies
          </label>

          {policyType === 'access' && (
            <>
              <div>
                <input
                  type="checkbox"
                  value="businessPartnerNumber"
                  checked={selectedSubPolicies.includes('businessPartnerNumber')}
                  onChange={handleSubPolicyChange}
                /> BusinessPartner Number Policy
              </div>
              <div>
                <input
                  type="checkbox"
                  value="activeMembership"
                  checked={selectedSubPolicies.includes('activeMembership')}
                  onChange={handleSubPolicyChange}
                /> Active Membership Policy
              </div>
              <div>
                <input
                  type="checkbox"
                  value="dismantlerPolicy"
                  checked={selectedSubPolicies.includes('dismantlerPolicy')}
                  onChange={handleSubPolicyChange}
                /> Dismantler Policy
              </div>
            </>
          )}

          {policyType === 'usage' && (
            <>
              <div>
                <input
                  type="checkbox"
                  value="frameworkAgreement"
                  checked={selectedSubPolicies.includes('frameworkAgreement')}
                  onChange={handleSubPolicyChange}
                /> Framework Agreement Policy
              </div>
              <div>
                <input
                  type="checkbox"
                  value="inForcePolicyDuration"
                  checked={selectedSubPolicies.includes('inForcePolicyDuration')}
                  onChange={handleSubPolicyChange}
                /> InForce Policy Duration
              </div>
              <div>
                <input
                  type="checkbox"
                  value="inForcePolicyFixed"
                  checked={selectedSubPolicies.includes('inForcePolicyFixed')}
                  onChange={handleSubPolicyChange}
                /> InForce Policy Fixed
              </div>
            </>
          )}
        </div>
      )}

      {/* Conditionally Render Input Fields Based on Selected Sub-Policies */}
      {selectedSubPolicies.includes('businessPartnerNumber') && (
        <div className="form-group" style={{ marginBottom: '15px', textAlign: 'center' }}>
          <label htmlFor="bpnNumber" style={{ fontWeight: 'bold', display: 'block' }}>BusinessPartner Number</label>
          <input
            type="text"
            id="bpnNumber"
            value={bpnNumber}
            onChange={(e) => setBpnNumber(e.target.value)}
            style={{ padding: '10px', width: '76%', borderRadius: '5px', textAlign: 'center' }}
            required
          />
        </div>
      )}

      {selectedSubPolicies.includes('activeMembership') && (
        <div className="form-group" style={{ marginBottom: '15px', textAlign: 'center' }}>
          <label htmlFor="status" style={{ fontWeight: 'bold', display: 'block' }}>BusinessPartner Status</label>
          <select
            id="status"
            value={mstatus}
            onChange={(e) => setMembershipstatus(e.target.value)}
            style={{ padding: '10px', width: '80%', borderRadius: '5px', textAlign: 'center' }}
            required
          >
            <option value="">-- Select Status --</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      )}

      {selectedSubPolicies.includes('frameworkAgreement') && (
        <div className="form-group" style={{ marginBottom: '15px', textAlign: 'center' }}>
          <label htmlFor="frameworkAgreement" style={{ fontWeight: 'bold', display: 'block' }}>Framework Agreement</label>
          <select
            id="frameworkAgreement"
            value={frameworkAgreement}
            onChange={(e) => setFrameworkAgreement(e.target.value)}
            style={{ padding: '10px', width: '80%', borderRadius: '5px', textAlign: 'center' }}
            required
          >
            <option value="">-- Select Agreement --</option>
            <option value="Pcf:1.0.0">Pcf:1.0.0</option>
            <option value="Traceability:1.0.0">Traceability:1.0.0</option>
            <option value="Quality:1.0.0">Quality:1.0.0</option>
            <option value="CircularEconomy:1.0.0">CircularEconomy:1.0.0</option>
            <option value="DemandCapacity:1.0.0">DemandCapacity:1.0.0</option>
            <option value="Puris:1.0.0">Puris:1.0.0</option>
            <option value="BusinessPartner:1.0.0">BusinessPartner:1.0.0</option>
            <option value="BehavioralTwin:1.0.0">BehavioralTwin:1.0.0</option>
          </select>
        </div>
      )}

      {selectedSubPolicies.includes('inForcePolicyDuration') && (
        <div className="form-group" style={{ marginBottom: '15px', textAlign: 'center' }}>
          <label htmlFor="fixedDays" style={{ fontWeight: 'bold', display: 'block' }}>Duration (in days)</label>
          <input
            type="number"
            id="fixedDays"
            value={fixedDays}
            onChange={(e) => setFixedDays(e.target.value)}
            style={{ padding: '10px', width: '76%', borderRadius: '5px', textAlign: 'center' }}
            required
          />
        </div>
      )}

      {selectedSubPolicies.includes('inForcePolicyFixed') && (
        <>
          <div className="form-group" style={{ marginBottom: '15px', textAlign: 'center' }}>
            <label htmlFor="dateFrom" style={{ fontWeight: 'bold', display: 'block' }}>Date From</label>
            <input
              type="date"
              id="dateFrom"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
              style={{ padding: '10px', width: '76%', borderRadius: '5px', textAlign: 'center' }}
              required
            />
          </div>
          <div className="form-group" style={{ marginBottom: '15px', textAlign: 'center' }}>
            <label htmlFor="dateTo" style={{ fontWeight: 'bold', display: 'block' }}>Date To</label>
            <input
              type="date"
              id="dateTo"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
              style={{ padding: '10px', width: '76%', borderRadius: '5px', textAlign: 'center' }}
              required
            />
          </div>
        </>
      )}

      {selectedSubPolicies.includes('dismantlerPolicy') && (
        <div className="form-group" style={{ marginBottom: '15px', textAlign: 'center' }}>
          <label htmlFor="dismantlerStatus" style={{ fontWeight: 'bold', display: 'block' }}>Dismantler Policy Status</label>
          <select
            id="dismantlerStatus"
            value={dismantlerStatus}
            onChange={(e) => setDismantlerStatus(e.target.value)}
            style={{ padding: '10px', width: '80%', borderRadius: '5px', textAlign: 'center' }}
            required
          >
            <option value="">-- Select Status --</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      )}

      {/* Submit Button */}
      <button type="submit">Submit</button>

      {/* Error/Success Messages */}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
    </form>
  );
};

export default CreatePolicyDefinitionForm;
